<template>
    <div>
        <el-card class="box-card" shadow="never">
            <el-button type="primary" @click="print()" class="my-10 text-right">
                <font-awesome-icon icon="fa-solid fa-print"/>
            </el-button>
            <el-row :gutter="30">
                <el-col :xs="12" :md="8" class="border-r-1">
                    <h4>Doctor/Employee's Information </h4>
                    <ul class="custom-box_list">
                        <li>
                            <span class="label">Name:</span>
                            <span class="value">{{ disbursedData.fullname }}</span>
                        </li>
                        <li>
                            <span class="label">Phone Number:</span>
                            <span class="value">{{ disbursedData.phone_number }}</span>
                        </li>
                        <li>
                            <span class="label">User Type:</span>
                            <span class="value">{{ disbursedData.user_type }}</span>
                        </li>
                    </ul>
                </el-col>
                <el-col :xs="12" :md="16" class="pl-20">
                    <h4>Disbursement Details</h4>
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12" class="mb-10">
                            <span class="label">UID:</span>
                            <span class="value">{{ disbursedData.uid }}</span>
                        </el-col>
                        <el-col :xs="24" :sm="12" class="mb-10">
                            <span class="label">Created Date:</span>
                            <span class="value">{{ disbursedData.created_at }}</span>
                        </el-col>
                        <el-col :xs="24" :sm="12" class="mb-10">
                            <span class="label">Disbursed Date:</span>
                            <span class="value">
                                <template v-if="disbursedData.disbursed_at">{{ disbursedData.disbursed_at }}</template>
                                <template v-else>N/A</template>
                            </span>
                        </el-col>
                        <el-col :xs="24" :sm="12" class="mb-10">
                            <span class="label">Amount:</span>
                            <span class="value">{{ disbursedData.amount }}</span>
                        </el-col>
                        <el-col :xs="24" :sm="12" class="mb-10">
                            <span class="label">Status:</span>
                            <span class="value">
                                <el-tag type="warning" v-if="disbursedData.status === 0">
                                    Pending
                                </el-tag>
                                <el-tag type="success" v-if="disbursedData.status === 1">
                                    Disbursed
                                </el-tag>
                            </span>
                        </el-col>
                        <el-col :xs="24" :sm="12" class="mb-10">
                            <span class="label">Payment Method:</span>
                            <span class="value">{{ disbursedData.payment_method }}</span>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-card>
        <div id="printArea">
            <!-- Invoice Patient's copy 
            <table id="invoice-head" style="position: relative; height:1in">
                <thead>
                    <tr>
                        <td>
                        <img style="max-width: 100%; width: 100%;" :src="require('@/assets/invoice_new_head.png')" alt="Head" class="header-img">
                        </td>
                    </tr>
                </thead>
            </table> -->
            <table style="width: 100%; padding: 1in;">
                <thead>
                    <tr>
                        <th style="width: 33%; padding-right: 1%; text-align: left; padding-bottom: 10px;">Doctor/Employee's Information</th>
                        <th style="width: 66%; padding-left: 2%; text-align: left; padding-bottom: 10px;">Disbursement Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="width: 33%; border-right: 1px solid #ccc; padding-right: 1%;">
                            <table>
                                <tr>
                                    <td style="padding-bottom: 10px;">
                                        <span class="label">Name:</span>
                                        <span class="value">{{ disbursedData.fullname }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding-bottom: 10px;">
                                        <span class="label">Phone Number:</span>
                                        <span class="value">{{ disbursedData.phone_number }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding-bottom: 10px;">
                                        <span class="label">Title :</span>
                                        <span class="value">{{ disbursedData.user_type }}</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td style="width: 66%; padding-left: 2%;" colspan="1">
                            <table style="width: 100%;">
                                <tr>
                                    <td colspan="1" style="padding-bottom: 10px;">
                                        <span class="label">UID:</span>
                                        <span class="value">{{ disbursedData.uid }}</span>
                                    </td>
                                    <td colspan="1" style="padding-bottom: 10px;">
                                        <span class="label">Created Date:</span>
                                        <span class="value">{{ disbursedData.created_at }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="1" style="padding-bottom: 10px;">
                                        <span class="label">Disbursed Date:</span>
                                        <span class="value">
                                            <template v-if="disbursedData.disbursed_at">{{ disbursedData.disbursed_at }}</template>
                                            <template v-else>N/A</template>
                                        </span>
                                    </td>
                                    <td colspan="1" style="padding-bottom: 10px;">
                                        <span class="label">Amount:</span>
                                        <span class="value">{{ disbursedData.amount }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="1" style="padding-bottom: 10px;">
                                        <span class="label">Status:</span>
                                        <span class="value">
                                            <el-tag type="warning" v-if="disbursedData.status === 0">
                                                Pending
                                            </el-tag>
                                            <el-tag type="success" v-if="disbursedData.status === 1">
                                                Disbursed
                                            </el-tag>
                                        </span>
                                    </td>
                                    <td colspan="1" style="padding-bottom: 10px;">
                                        <span class="label">Payment Method:</span>
                                        <span class="value">{{ disbursedData.payment_method }}</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
    return{
        disbursedData: {},
        printPage: false,
    }
    },
    methods: {
        disburse() {
            axios.get(`api/v2/referrals/disbursements/${this.$route.params.uid ? this.$route.params.uid : this.$route.query.uid}`)
            .then(res => {
                this.disbursedData = res.data.data;
            })
        },
    print() {
      this.printPage = true;
      setTimeout(() => {
        this.printSetup();
      }, 50);
    },

    printSetup() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('printArea').innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
        <head>
            <title>Disbursement Details</title>
            ${stylesHtml}
        </head>
        <body>
            ${prtHtml}
        </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.addEventListener('load', () => {
        setTimeout(() => {
          WinPrint.print();
          WinPrint.close();
          this.printPage = false;
        }, 200);
      });
    },
    },
    created() {
        this.disburse();
    },
}
</script>
<style scoped>
@page {
  size: A4;
  /* width: 8.5in;
  height: 5.5in; */
  margin-top: 1.5in;
  margin: 0;
  padding: 1in;
}

@media print {
    #printArea{
        display: block;
    }
}

#printArea{
    /* display: none; */
}
.border-r-1{
    border-right: 1px solid #ccc;
}
.ml-2{
    margin-left: 8px;
}
.mt-0{
    margin-top: 0;
}
.mb-10{
    margin-bottom: 10px;
}
.pl-20{
    padding-left: 20px !important;
}

.value,
.label{
    font-size: 12px;
    color: #000;
    display: block;
    line-height: 14px;
}

.label{
    font-weight: 600;
    margin-bottom: 5px;
}

.custom-box_list{
    padding: 0;
    margin: 0;
    list-style: none;
}
.custom-box_list li{
    margin-bottom: 10px;
}

.mt-10{
    margin-top: 10px;
}
</style>